<template>
  <v-container fluid>
    <CustomerContainerBoxFilter v-on:onFilterChange="onFilterChange"></CustomerContainerBoxFilter>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('container-box.customer-list')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="getHeaders()"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:item.end_box="{ item }">
          <!-- <div style="background: red"></div> -->
          {{ get_end_box(item) }}
        </template>
        <template v-slot:item.supplier="{ item }">
          {{ get_supplier_name(item) }}
        </template>
        <template v-slot:item.customer="{ item }">
          {{ get_customer_name(item) }}
        </template>
        <template v-slot:item.commodity.customs_type="{ item }">
          {{ get_customs_type_name(item) }}
        </template>
        <template v-slot:item.container.stuffing_date="{ item }">
          {{ get_stuffing_date(item) }}
        </template>
        <template v-slot:item.container.cutoff_date="{ item }">
          {{ get_cutoff_date(item) }}
        </template>
        <template v-slot:item.transport_status="{ item }">
          {{ item.transport_status | getText(transport_status_items) | Translate }}
        </template>
        <template v-slot:item.receive_time="{ item }">
          {{ get_receive_time(item) | Translate }}
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import BaseTable from "./BaseTable.vue";
import { transport_status_items } from "@/definition.js";
import CustomerContainerBoxFilter from "../filters/CustomerContainerBoxFilter"
import { mapState } from 'vuex'
import moment from "moment";
export default {
  data: (vm) => ({
    url: "/container/container_box/",
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("container.code"),
        value: "code",
        width: "4%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.box_str"),
        value: "start_box",
        width: "1%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.number_str"),
        value: "end_box",
        width: "1%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.name"),
        value: "commodity.name",
      },
      // {
      //   sortable: true,
      //   text: vm.$i18n.t("commodity.number"),
      //   value: "commodity.commodity_number",
      // },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.customs-type"),
        value: "commodity.customs_type",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.commodity_per_box"),
        value: "commodity_per_box",
        width: "6%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.box_amount"),
        value: "box_amount",
        width: "6%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.cubic_feet"),
        value: "cubic_feet",
        width: "7%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("supplier.supplier"),
        value: "supplier",
        width: "8%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("customer.customer"),
        value: "customer",
        hide: [10],
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.number"),
        value: "container.container_number",
        // hide: [10],
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.stuffing-date"),
        value: "container.stuffing_date",
        // hide: [10],
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.cutoff-date"),
        value: "container.cutoff_date",
        // hide: [10],
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.transport-status"),
        value: "transport_status",
        width: "8%",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container-box.receive-date"),
        value: "receive_time",
      },
    ],
    transport_status_items: transport_status_items,
  }),
  mixins: [BaseTable],
  components: {
    CustomerContainerBoxFilter
  },
  computed: {
    ...mapState(["role","user"]),
  },
  methods: {
    getHeaders() {
      const role = this.role
      // const container_isnull = this.container_isnull;
      return this.headers.filter(function (e) {
        return e.hide === undefined || !e.hide.includes(role);
      });
    },
    get_end_box(item) {
      return item.box_amount > 1 ? item.end_box : "";
    },
    get_supplier_name(item) {
      return this.getHanText(item.supplier, "name");
    },
    get_customer_name(item) {
      return this.getHanText(item.customer, "name");
    },
    get_customs_type_name(item) {
      return item.commodity.customs_type
        ? this.getHanText(item.commodity.customs_type, "name")
        : "";
    },
    isDateOver(item) {
      const date1 = new Date();
      const date2 = new Date(item.container.cutoff_date);
      return date1 - date2 > 0;
    },
    get_receive_time(item) {
      return item.receive_time ? moment(item.receive_time).utc(true).format('MM-DD HH:mm') : ''
    },
    get_stuffing_date(item){
      console.log(item)
      return item.container? moment(item.container.stuffing_date).format('MM-DD') : ''
    },
    get_cutoff_date(item){
      console.log(item)
      return item.container? moment(item.container.cutoff_date).format('MM-DD') : ''
    }
  },
  mounted() {
    if (this.role==10) {
      this.getParams["customer"] = this.user.related_id;
    }
  },
};
</script>