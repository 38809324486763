var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('CustomerContainerBoxFilter',{on:{"onFilterChange":_vm.onFilterChange}}),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":_vm.$t('container-box.customer-list')}},[_c('v-data-table',{attrs:{"headers":_vm.getHeaders(),"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.end_box",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_end_box(item))+" ")]}},{key:"item.supplier",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_supplier_name(item))+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_customer_name(item))+" ")]}},{key:"item.commodity.customs_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_customs_type_name(item))+" ")]}},{key:"item.container.stuffing_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_stuffing_date(item))+" ")]}},{key:"item.container.cutoff_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_cutoff_date(item))+" ")]}},{key:"item.transport_status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("Translate")(_vm._f("getText")(item.transport_status,_vm.transport_status_items)))+" ")]}},{key:"item.receive_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("Translate")(_vm.get_receive_time(item)))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }