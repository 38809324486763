<template>
    <v-container>
        <v-row>
          <v-col
            cols="6"
            md="3"
          >
            <v-text-field
              v-model="filterItem.container__container_number__startswith"
              :label="$t('container.number')"
              outlined
              dense
              clearable
            ></v-text-field>
          </v-col>
          <!-- <v-col
              cols="6"
              md="3"
          >
            <CommonAutocomplete
              v-model="filterItem.supplier"
              :label="$t('supplier.supplier')"
              url="/supplier/supplier/"
              :item_text="nameField"
              item_value="id"
              :query_field="nameFieldFilter"
              :extra_filter="filterItem"
            ></CommonAutocomplete>
          </v-col> -->
          <v-col
            cols="6"
            md="3"
          >
            <v-select
              v-model="filterItem.transport_status"
              :label="$t('container.transport-status')"
              :items="transport_status_items"
              :item-text="item => $t(item.text)"
              dense
              outlined
              clearable
            ></v-select>
          </v-col>
          <v-col>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="onChange"
            >
              <v-icon left>mdi-magnify</v-icon>
              {{ $t("search") }}
            </v-btn>
          </v-col>
        </v-row>
    </v-container>
</template>
<script>
import BaseFilter from "./BaseFilter";
import CommonAutocomplete from "./CommonAutocomplete";
import { transport_status_items } from "@/definition.js";
export default {
  data: (vm) => ({
    filterItem: {
      supplier: null,
    },
    transport_status_items: transport_status_items
  }),
  components: {
    CommonAutocomplete,
  },
  mixins: [BaseFilter],
  methods: {},
};
</script>